const COUNTRIES = [
  {
    name: "Afghanistan",
    iso: "AFG",
    prefix: "+93",
  },
  {
    name: "Albania",
    iso: "ALB",
    prefix: "+355",
  },
  {
    name: "Algeria",
    iso: "DZA",
    prefix: "+213",
  },
  {
    name: "American Samoa",
    iso: "ASM",
    prefix: "+1684",
  },
  {
    name: "Andorra",
    iso: "AND",
    prefix: "+376",
  },
  {
    name: "Angola",
    iso: "AGO",
    prefix: "+244",
  },
  {
    name: "Anguilla",
    iso: "AIA",
    prefix: "+1264",
  },
  {
    name: "Antigua and Barbuda",
    iso: "ATG",
    prefix: "+1268",
  },
  {
    name: "Argentina",
    iso: "ARG",
    prefix: "+54",
  },
  {
    name: "Armenia",
    iso: "ARM",
    prefix: "+374",
  },
  {
    name: "Aruba",
    iso: "ABW",
    prefix: "+297",
  },
  {
    name: "Australia",
    iso: "AUS",
    prefix: "+61",
  },
  {
    name: "Austria",
    iso: "AUT",
    prefix: "+43",
  },
  {
    name: "Azerbaijan",
    iso: "AZE",
    prefix: "+994",
  },
  {
    name: "Bahamas",
    iso: "BHS",
    prefix: "+1242",
  },
  {
    name: "Bahrain",
    iso: "BHR",
    prefix: "+973",
  },
  {
    name: "Bangladesh",
    iso: "BGD",
    prefix: "+880",
  },
  {
    name: "Barbados",
    iso: "BRB",
    prefix: "+1246",
  },
  {
    name: "Belarus",
    iso: "BLR",
    prefix: "+375",
  },
  {
    name: "Belgium",
    iso: "BEL",
    prefix: "+32",
  },
  {
    name: "Belize",
    iso: "BLZ",
    prefix: "+501",
  },
  {
    name: "Benin",
    iso: "BEN",
    prefix: "+229",
  },
  {
    name: "Bermuda",
    iso: "BMU",
    prefix: "+1441",
  },
  {
    name: "Bhutan",
    iso: "BTN",
    prefix: "+975",
  },
  {
    name: "Bolivia",
    iso: "BOL",
    prefix: "+591",
  },
  {
    name: "Bosnia and Herzegovina",
    iso: "BIH",
    prefix: "+387",
  },
  {
    name: "Botswana",
    iso: "BWA",
    prefix: "+267",
  },
  {
    name: "Brazil",
    iso: "BRA",
    prefix: "+55",
  },
  {
    name: "Brunei",
    iso: "BRN",
    prefix: "+673",
  },
  {
    name: "Bulgaria",
    iso: "BGR",
    prefix: "+359",
  },
  {
    name: "Burkina Faso",
    iso: "BFA",
    prefix: "+226",
  },
  {
    name: "Burundi",
    iso: "BDI",
    prefix: "+257",
  },
  {
    name: "Cambodia",
    iso: "KHM",
    prefix: "+855",
  },
  {
    name: "Cameroon",
    iso: "CMR",
    prefix: "+237",
  },
  {
    name: "Canada",
    iso: "CAN",
    prefix: "+1",
  },
  {
    name: "Cape Verde",
    iso: "CPV",
    prefix: "+238",
  },
  {
    name: "Cayman Islands",
    iso: "CYM",
    prefix: "+1345",
  },
  {
    name: "Central African Republic",
    iso: "CAF",
    prefix: "+236",
  },
  {
    name: "Chad",
    iso: "TCD",
    prefix: "+235",
  },
  {
    name: "Chile",
    iso: "CHL",
    prefix: "+56",
  },
  {
    name: "China",
    iso: "CHN",
    prefix: "+86",
  },
  {
    name: "Colombia",
    iso: "COL",
    prefix: "+57",
  },
  {
    name: "Comoros",
    iso: "COM",
    prefix: "+269",
  },
  {
    name: "Republic of the Congo",
    iso: "COG",
    prefix: "+242",
  },
  {
    name: "DR Congo",
    iso: "COD",
    prefix: "+242",
  },
  {
    name: "Cook Islands",
    iso: "COK",
    prefix: "+682",
  },
  {
    name: "Costa Rica",
    iso: "CRI",
    prefix: "+506",
  },
  {
    name: "Ivory Coast",
    iso: "CIV",
    prefix: "+225",
  },
  {
    name: "Croatia",
    iso: "HRV",
    prefix: "+385",
  },
  {
    name: "Cuba",
    iso: "CUB",
    prefix: "+53",
  },
  {
    name: "Cyprus",
    iso: "CYP",
    prefix: "+357",
  },
  {
    name: "Czech Republic",
    iso: "CZE",
    prefix: "+420",
  },
  {
    name: "Denmark",
    iso: "DNK",
    prefix: "+45",
  },
  {
    name: "Djibouti",
    iso: "DJI",
    prefix: "+253",
  },
  {
    name: "Dominica",
    iso: "DMA",
    prefix: "+1767",
  },
  {
    name: "Dominican Republic",
    iso: "DOM",
    prefix: "+1809",
  },
  {
    name: "Ecuador",
    iso: "ECU",
    prefix: "+593",
  },
  {
    name: "Egypt",
    iso: "EGY",
    prefix: "+20",
  },
  {
    name: "El Salvador",
    iso: "SLV",
    prefix: "+503",
  },
  {
    name: "Equatorial Guinea",
    iso: "GNQ",
    prefix: "+240",
  },
  {
    name: "Eritrea",
    iso: "ERI",
    prefix: "+291",
  },
  {
    name: "Estonia",
    iso: "EST",
    prefix: "+372",
  },
  {
    name: "Ethiopia",
    iso: "ETH",
    prefix: "+251",
  },
  {
    name: "Falkland Islands",
    iso: "FLK",
    prefix: "+500",
  },
  {
    name: "Faroe Islands",
    iso: "FRO",
    prefix: "+298",
  },
  {
    name: "Fiji",
    iso: "FJI",
    prefix: "+679",
  },
  {
    name: "Finland",
    iso: "FIN",
    prefix: "+358",
  },
  {
    name: "France",
    iso: "FRA",
    prefix: "+33",
  },
  {
    name: "French Guiana",
    iso: "GUF",
    prefix: "+594",
  },
  {
    name: "French Polynesia",
    iso: "PYF",
    prefix: "+689",
  },
  {
    name: "Gabon",
    iso: "GAB",
    prefix: "+241",
  },
  {
    name: "Gambia",
    iso: "GMB",
    prefix: "+220",
  },
  {
    name: "Georgia",
    iso: "GEO",
    prefix: "+995",
  },
  {
    name: "Germany",
    iso: "DEU",
    prefix: "+49",
  },
  {
    name: "Ghana",
    iso: "GHA",
    prefix: "+233",
  },
  {
    name: "Gibraltar",
    iso: "GIB",
    prefix: "+350",
  },
  {
    name: "Greece",
    iso: "GRC",
    prefix: "+30",
  },
  {
    name: "Greenland",
    iso: "GRL",
    prefix: "+299",
  },
  {
    name: "Grenada",
    iso: "GRD",
    prefix: "+1473",
  },
  {
    name: "Guadeloupe",
    iso: "GLP",
    prefix: "+590",
  },
  {
    name: "Guam",
    iso: "GUM",
    prefix: "+1671",
  },
  {
    name: "Guatemala",
    iso: "GTM",
    prefix: "+502",
  },
  {
    name: "Guinea",
    iso: "GIN",
    prefix: "+224",
  },
  {
    name: "Guinea-Bissau",
    iso: "GNB",
    prefix: "+245",
  },
  {
    name: "Guyana",
    iso: "GUY",
    prefix: "+592",
  },
  {
    name: "Haiti",
    iso: "HTI",
    prefix: "+509",
  },
  {
    name: "Vatican City",
    iso: "VAT",
    prefix: "+39",
  },
  {
    name: "Honduras",
    iso: "HND",
    prefix: "+504",
  },
  {
    name: "Hong Kong",
    iso: "HKG",
    prefix: "+852",
  },
  {
    name: "Hungary",
    iso: "HUN",
    prefix: "+36",
  },
  {
    name: "Iceland",
    iso: "ISL",
    prefix: "+354",
  },
  {
    name: "India",
    iso: "IND",
    prefix: "+91",
  },
  {
    name: "Indonesia",
    iso: "IDN",
    prefix: "+62",
  },
  {
    name: "Iran",
    iso: "IRN",
    prefix: "+98",
  },
  {
    name: "Iraq",
    iso: "IRQ",
    prefix: "+964",
  },
  {
    name: "Ireland",
    iso: "IRL",
    prefix: "+353",
  },
  {
    name: "Israel",
    iso: "ISR",
    prefix: "+972",
  },
  {
    name: "Italy",
    iso: "ITA",
    prefix: "+39",
  },
  {
    name: "Jamaica",
    iso: "JAM",
    prefix: "+1876",
  },
  {
    name: "Japan",
    iso: "JPN",
    prefix: "+81",
  },
  {
    name: "Jordan",
    iso: "JOR",
    prefix: "+962",
  },
  {
    name: "Kazakhstan",
    iso: "KAZ",
    prefix: "+7",
  },
  {
    name: "Kenya",
    iso: "KEN",
    prefix: "+254",
  },
  {
    name: "Kiribati",
    iso: "KIR",
    prefix: "+686",
  },
  {
    name: "North Korea",
    iso: "PRK",
    prefix: "+850",
  },
  {
    name: "South Korea",
    iso: "KOR",
    prefix: "+82",
  },
  {
    name: "Kuwait",
    iso: "KWT",
    prefix: "+965",
  },
  {
    name: "Kyrgyzstan",
    iso: "KGZ",
    prefix: "+996",
  },
  {
    name: "Laos",
    iso: "LAO",
    prefix: "+856",
  },
  {
    name: "Latvia",
    iso: "LVA",
    prefix: "+371",
  },
  {
    name: "Lebanon",
    iso: "LBN",
    prefix: "+961",
  },
  {
    name: "Lesotho",
    iso: "LSO",
    prefix: "+266",
  },
  {
    name: "Liberia",
    iso: "LBR",
    prefix: "+231",
  },
  {
    name: "Libya",
    iso: "LBY",
    prefix: "+218",
  },
  {
    name: "Liechtenstein",
    iso: "LIE",
    prefix: "+423",
  },
  {
    name: "Lithuania",
    iso: "LTU",
    prefix: "+370",
  },
  {
    name: "Luxembourg",
    iso: "LUX",
    prefix: "+352",
  },
  {
    name: "Macau",
    iso: "MAC",
    prefix: "+853",
  },
  {
    name: "Macedonia",
    iso: "MKD",
    prefix: "+389",
  },
  {
    name: "Madagascar",
    iso: "MDG",
    prefix: "+261",
  },
  {
    name: "Malawi",
    iso: "MWI",
    prefix: "+265",
  },
  {
    name: "Malaysia",
    iso: "MYS",
    prefix: "+60",
  },
  {
    name: "Maldives",
    iso: "MDV",
    prefix: "+960",
  },
  {
    name: "Mali",
    iso: "MLI",
    prefix: "+223",
  },
  {
    name: "Malta",
    iso: "MLT",
    prefix: "+356",
  },
  {
    name: "Marshall Islands",
    iso: "MHL",
    prefix: "+692",
  },
  {
    name: "Martinique",
    iso: "MTQ",
    prefix: "+596",
  },
  {
    name: "Mauritania",
    iso: "MRT",
    prefix: "+222",
  },
  {
    name: "Mauritius",
    iso: "MUS",
    prefix: "+230",
  },
  {
    name: "Mexico",
    iso: "MEX",
    prefix: "+52",
  },
  {
    name: "Micronesia",
    iso: "FSM",
    prefix: "+691",
  },
  {
    name: "Moldova",
    iso: "MDA",
    prefix: "+373",
  },
  {
    name: "Monaco",
    iso: "MCO",
    prefix: "+377",
  },
  {
    name: "Mongolia",
    iso: "MNG",
    prefix: "+976",
  },
  {
    name: "Montserrat",
    iso: "MSR",
    prefix: "+1664",
  },
  {
    name: "Morocco",
    iso: "MAR",
    prefix: "+212",
  },
  {
    name: "Mozambique",
    iso: "MOZ",
    prefix: "+258",
  },
  {
    name: "Myanmar",
    iso: "MMR",
    prefix: "+95",
  },
  {
    name: "Namibia",
    iso: "NAM",
    prefix: "+264",
  },
  {
    name: "Nauru",
    iso: "NRU",
    prefix: "+674",
  },
  {
    name: "Nepal",
    iso: "NPL",
    prefix: "+977",
  },
  {
    name: "Netherlands",
    iso: "NLD",
    prefix: "+31",
  },
  {
    name: "New Caledonia",
    iso: "NCL",
    prefix: "+687",
  },
  {
    name: "New Zealand",
    iso: "NZL",
    prefix: "+64",
  },
  {
    name: "Nicaragua",
    iso: "NIC",
    prefix: "+505",
  },
  {
    name: "Niger",
    iso: "NER",
    prefix: "+227",
  },
  {
    name: "Nigeria",
    iso: "NGA",
    prefix: "+234",
  },
  {
    name: "Niue",
    iso: "NIU",
    prefix: "+683",
  },
  {
    name: "Norfolk Island",
    iso: "NFK",
    prefix: "+672",
  },
  {
    name: "Northern Mariana Islands",
    iso: "MNP",
    prefix: "+1670",
  },
  {
    name: "Norway",
    iso: "NOR",
    prefix: "+47",
  },
  {
    name: "Oman",
    iso: "OMN",
    prefix: "+968",
  },
  {
    name: "Pakistan",
    iso: "PAK",
    prefix: "+92",
  },
  {
    name: "Palau",
    iso: "PLW",
    prefix: "+680",
  },
  {
    name: "Panama",
    iso: "PAN",
    prefix: "+507",
  },
  {
    name: "Papua New Guinea",
    iso: "PNG",
    prefix: "+675",
  },
  {
    name: "Paraguay",
    iso: "PRY",
    prefix: "+595",
  },
  {
    name: "Peru",
    iso: "PER",
    prefix: "+51",
  },
  {
    name: "Philippines",
    iso: "PHL",
    prefix: "+63",
  },
  {
    name: "Pitcairn Islands",
    iso: "PCN",
    prefix: "+0",
  },
  {
    name: "Poland",
    iso: "POL",
    prefix: "+48",
  },
  {
    name: "Portugal",
    iso: "PRT",
    prefix: "+351",
  },
  {
    name: "Puerto Rico",
    iso: "PRI",
    prefix: "+1787",
  },
  {
    name: "Qatar",
    iso: "QAT",
    prefix: "+974",
  },
  {
    name: "Réunion",
    iso: "REU",
    prefix: "+262",
  },
  {
    name: "Romania",
    iso: "ROU",
    prefix: "+40",
  },
  {
    name: "Russia",
    iso: "RUS",
    prefix: "+70",
  },
  {
    name: "Rwanda",
    iso: "RWA",
    prefix: "+250",
  },
  {
    name: "Saint Kitts and Nevis",
    iso: "KNA",
    prefix: "+1869",
  },
  {
    name: "Saint Lucia",
    iso: "LCA",
    prefix: "+1758",
  },
  {
    name: "Saint Pierre and Miquelon",
    iso: "SPM",
    prefix: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso: "VCT",
    prefix: "+1784",
  },
  {
    name: "Samoa",
    iso: "WSM",
    prefix: "+684",
  },
  {
    name: "San Marino",
    iso: "SMR",
    prefix: "+378",
  },
  {
    name: "São Tomé and Príncipe",
    iso: "STP",
    prefix: "+239",
  },
  {
    name: "Saudi Arabia",
    iso: "SAU",
    prefix: "+966",
  },
  {
    name: "Senegal",
    iso: "SEN",
    prefix: "+221",
  },
  {
    name: "Serbia",
    iso: "SRB",
    prefix: "+381",
  },
  {
    name: "Montenegro",
    iso: "MNE",
    prefix: "+382",
  },
  {
    name: "Seychelles",
    iso: "SYC",
    prefix: "+248",
  },
  {
    name: "Sierra Leone",
    iso: "SLE",
    prefix: "+232",
  },
  {
    name: "Singapore",
    iso: "SGP",
    prefix: "+65",
  },
  {
    name: "Slovakia",
    iso: "SVK",
    prefix: "+421",
  },
  {
    name: "Slovenia",
    iso: "SVN",
    prefix: "+386",
  },
  {
    name: "Solomon Islands",
    iso: "SLB",
    prefix: "+677",
  },
  {
    name: "Somalia",
    iso: "SOM",
    prefix: "+252",
  },
  {
    name: "South Africa",
    iso: "ZAF",
    prefix: "+27",
  },
  {
    name: "Spain",
    iso: "ESP",
    prefix: "+34",
  },
  {
    name: "Sri Lanka",
    iso: "LKA",
    prefix: "+94",
  },
  {
    name: "Sudan",
    iso: "SDN",
    prefix: "+249",
  },
  {
    name: "Suriname",
    iso: "SUR",
    prefix: "+597",
  },
  {
    name: "Svalbard and Jan Mayen",
    iso: "SJM",
    prefix: "+47",
  },
  {
    name: "Swaziland",
    iso: "SWZ",
    prefix: "+268",
  },
  {
    name: "Sweden",
    iso: "SWE",
    prefix: "+46",
  },
  {
    name: "Switzerland",
    iso: "CHE",
    prefix: "+41",
  },
  {
    name: "Syria",
    iso: "SYR",
    prefix: "+963",
  },
  {
    name: "Taiwan",
    iso: "TWN",
    prefix: "+886",
  },
  {
    name: "Tajikistan",
    iso: "TJK",
    prefix: "+992",
  },
  {
    name: "Tanzania",
    iso: "TZA",
    prefix: "+255",
  },
  {
    name: "Thailand",
    iso: "THA",
    prefix: "+66",
  },
  {
    name: "Togo",
    iso: "TGO",
    prefix: "+228",
  },
  {
    name: "Tokelau",
    iso: "TKL",
    prefix: "+690",
  },
  {
    name: "Tonga",
    iso: "TON",
    prefix: "+676",
  },
  {
    name: "Trinidad and Tobago",
    iso: "TTO",
    prefix: "+1868",
  },
  {
    name: "Tunisia",
    iso: "TUN",
    prefix: "+216",
  },
  {
    name: "Turkey",
    iso: "TUR",
    prefix: "+90",
  },
  {
    name: "Turkmenistan",
    iso: "TKM",
    prefix: "+7370",
  },
  {
    name: "Turks and Caicos Islands",
    iso: "TCA",
    prefix: "+1649",
  },
  {
    name: "Tuvalu",
    iso: "TUV",
    prefix: "+688",
  },
  {
    name: "Uganda",
    iso: "UGA",
    prefix: "+256",
  },
  {
    name: "Ukraine",
    iso: "UKR",
    prefix: "+380",
  },
  {
    name: "United Arab Emirates",
    iso: "ARE",
    prefix: "+971",
  },
  {
    name: "United Kingdom",
    iso: "GBR",
    prefix: "+44",
  },
  {
    name: "United States",
    iso: "USA",
    prefix: "+1",
  },
  {
    name: "Uruguay",
    iso: "URY",
    prefix: "+598",
  },
  {
    name: "Uzbekistan",
    iso: "UZB",
    prefix: "+998",
  },
  {
    name: "Vanuatu",
    iso: "VUT",
    prefix: "+678",
  },
  {
    name: "Venezuela",
    iso: "VEN",
    prefix: "+58",
  },
  {
    name: "Vietnam",
    iso: "VNM",
    prefix: "+84",
  },
  {
    name: "British Virgin Islands",
    iso: "VGB",
    prefix: "+1284",
  },
  {
    name: "United States Virgin Islands",
    iso: "VIR",
    prefix: "+1340",
  },
  {
    name: "Wallis and Futuna",
    iso: "WLF",
    prefix: "+681",
  },
  {
    name: "Western Sahara",
    iso: "ESH",
    prefix: "+212",
  },
  {
    name: "Yemen",
    iso: "YEM",
    prefix: "+967",
  },
  {
    name: "Zambia",
    iso: "ZMB",
    prefix: "+260",
  },
  {
    name: "Zimbabwe",
    iso: "ZWE",
    prefix: "+263",
  },
];

const getCountryDialCode = (country) =>
  country && COUNTRIES.find(({ iso }) => iso === country).prefix;

const getCountryFull = (country) =>
  country && COUNTRIES.find(({ iso }) => iso === country).name;

export { COUNTRIES, getCountryDialCode, getCountryFull };
