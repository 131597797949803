import React, { useState } from "react";
import Layout2 from "../components/layout-2";
import SEO from "../components/seo";
import {
  Link as Anchor,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputLeftAddon,
  InputGroup,
  Textarea,
  Select,
  Flex,
  Button,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../components/contact-form";

const ContactPage = () => {
  const [activeForm, setActiveForm] = useState("customer");

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Kaminds Nutrichem Private Limited",
    alternateName: "kaminds",
    url: " https://www.kaminds.com/contact/ ",
    logo:
      "https://www.kaminds.com/static/d2284c154057c362c68fe045665929d5/63311/Trademark%20Kaminds_RGB%20Logo_Logo_Trademark.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917568015258",
      contactType: "technical support",
      areaServed: "IN",
      availableLanguage: ["en", "Hindi"],
    },
    sameAs: [
      "https://www.linkedin.com/company/kaminds",
      " https://www.kaminds.com/contact/ ",
    ],
  };

  return (
    <Layout2>
      <SEO
        title="Contact"
        description="Phone: (+91) 75680-15258 Email: contact@kaminds.com How can we help you? You are welcome to send us a message through the contact form. We will make sure your message arrives at the desk of the right contact person."
        keywords="contact, Kaminds, service"
        links={[{ rel: "canonical", href: "https://www.kaminds.com/contact/" }]}
        schemaMarkup={schemaMarkup}
      />
      <Box
        marginTop={["80px", "80px", "80px", "100px", "100px"]}
        position="relative"
      >
        <Box
          display={["block", "none", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/contact-us-small.jpg"
            // width={800}
            height={1100}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="contact-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "block", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/contact-us-medium.jpg"
            width={800}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="contact-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "block", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/contact-us.jpg"
            width={1920}
            height={900}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="contact-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "block", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/contact-us.jpg"
            width={1920}
            height={800}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="contact-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "none", "block"]}
          position="relative"
        >
          <StaticImage
            src="../images/contact-us.jpg"
            width={1920}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="contact-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          margin="auto"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Box maxWidth="1300px" width="100%" margin="0 auto">
            <Box
              as="h1"
              fontSize={[
                "70px",
                "80px",
                "85px",
                "100px",
                "115px",
                "125px",
                // "120px",
              ]}
              fontWeight="500"
              color="#fff"
              width="100%"
              fontFamily="Fjalla One !important"
              textAlign="center"
              lineHeight={["75px", "85px", "90px", "105px", "100px", "135px"]}
            >
              Contact Us
            </Box>
            {/* <Box as="p" textAlign="center" color="#fff" fontSize="30px">
              Together for a better future
            </Box> */}
          </Box>
        </Box>
      </Box>
      <Box
        // paddingBottom="100px"
        // paddingTop="100px"
        // background="#282863"
        marginTop="80px"
      >
        {/* <StaticImage
          src="../images/contact-us.png"
          width={1920}
          height={480}
          style={{ display: "block" }}
        /> */}
        {/* <h2
          style={{
            color: "white",
            fontFamily: "Roboto Condensed !important",
            fontSize: "40px",
            margin: "auto",
            textAlign: "center",
          }}
        >
          GET IN TOUCH
        </h2> */}
      </Box>
      {/* <Box paddingTop="100px" paddingBottom="100px">
        <Box maxWidth="1100px" width="100%" margin="auto">
          <Box display="flex" justifyContent="space-between">
            <Box maxWidth="33.33333%" width="100%" margin="0 15px">
              <Box
                textAlign="center"
                background="#fff"
                padding="30px"
                boxShadow="0 2px 48px 0 rgba(0,0,0,.08)"
              >
                <Box
                  width="75px"
                  height="75px"
                  background="#d61215"
                  display="inline-block"
                  borderRadius="50%"
                  fontSize="25px"
                  lineHeight="73px"
                  color="white"
                >
                  <IoMailOutline style={{ display: "inherit" }} />
                </Box>
                <Box
                  as="h2"
                  margin="25px 0 15px"
                  fontSize="20px"
                  fontWeight="400"
                >
                  Email Us
                </Box>
                <Box as="p" fontSize="16px">
                  <a href="mailto:sales@kaminds.com">sales@kaminds.com</a>
                </Box>
              </Box>
            </Box>
            <Box maxWidth="33.33333%" width="100%" margin="0 15px">
              <Box
                textAlign="center"
                background="#fff"
                padding="30px"
                boxShadow="0 2px 48px 0 rgba(0,0,0,.08)"
              >
                <Box
                  width="75px"
                  height="75px"
                  background="#d61215"
                  display="inline-block"
                  borderRadius="50%"
                  fontSize="25px"
                  lineHeight="73px"
                  color="white"
                >
                  <IoLocationOutline style={{ display: "inherit" }} />
                </Box>
                <Box
                  as="h2"
                  margin="25px 0 15px"
                  fontSize="20px"
                  fontWeight="400"
                >
                  Reach Us
                </Box>
                <Box as="p" fontSize="16px">
                  E-1, Surajpole Anaj Mandi, Near Galta Gate, Jaipur - 302004
                </Box>
              </Box>
            </Box>
            <Box maxWidth="33.33333%" width="100%" margin="0 15px">
              <Box
                textAlign="center"
                background="#fff"
                padding="30px"
                boxShadow="0 2px 48px 0 rgba(0,0,0,.08)"
              >
                <Box
                  width="75px"
                  height="75px"
                  background="#d61215"
                  display="inline-block"
                  borderRadius="50%"
                  fontSize="25px"
                  lineHeight="73px"
                  color="white"
                >
                  <IoCallOutline style={{ display: "inherit" }} />
                </Box>
                <Box
                  as="h2"
                  margin="25px 0 15px"
                  fontSize="20px"
                  fontWeight="400"
                >
                  Call Us
                </Box>
                <Box as="p" fontSize="16px">
                  <a href="tel:1234567890">+91 1234567890</a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}
      <Box paddingTop="50px" padding="2em">
        <Flex
          maxWidth="1400px"
          width="100%"
          margin="auto"
          justifyContent="space-between"
          wrap="wrap"
        >
          <Flex
            width={["100%", "100%", "100%", "45%", "45%"]}
            background="linen"
            padding="2em"
            borderRadius="30px"
          >
            <Box
              textAlign="left"
              marginTop={["0px", "0px", "0px", "80px", "80px"]}
            >
              <Box
                as="h2"
                fontSize={["40px", "50px", "60px", "70px", "70px"]}
                fontWeight="500"
                marginTop="5px"
                marginBottom="15px"
                color="#252869"
                marginTop="20px"
              >
                How can we help you?
              </Box>
              {/* <Box
              height="4px"
              width="85px"
              background="#d61215"
              margin="20px 25px"
            ></Box> */}
              <Box
                as="p"
                fontSize="18px"
                fontWeight="400"
                width="100%"
                marginTop="20px"
                color="#252869"
              >
                You are welcome to send us a message through the contact form.
                We will make sure your message arrives at the desk of the right
                contact person.
              </Box>
            </Box>
          </Flex>
          {/* <Flex marginTop="30px">
            <Box
              as="h2"
              fontSize="40px"
              fontWeight="600"
              color={activeForm === "customer" ? "#252869" : "#c0c2ed"}
              onClick={() => setActiveForm("customer")}
              cursor="pointer"
            >
              as a customer
            </Box>
            <Box
              marginLeft="40px"
              as="h2"
              fontWeight="600"
              fontSize="40px"
              color={activeForm === "manufacturer" ? "#252869" : "#c0c2ed"}
              onClick={() => setActiveForm("manufacturer")}
              cursor="pointer"
            >
              as a manufacturer
            </Box>
          </Flex> */}
          <ContactForm />
        </Flex>
      </Box>
      <Box marginTop="150px" padding="2em">
        <Box
          position="relative"
          maxWidth="1300px"
          margin="auto"
          padding="2em"
          background="linen"
          borderRadius="30px"
        >
          <Flex wrap="wrap">
            <Flex
              marginTop={["60px", "150px", "180px", "0px", "0px"]}
              direction="column"
              width={["100%", "100%", "100%", "58%", "60%"]}
              paddingLeft="1.5em"
              paddingTop="2.5em"
              paddingBottom="2.5em"
              marginLeft={["auto", "auto", "auto", "0", "0"]}
              marginRight={["auto", "auto", "auto", "0", "0"]}
            >
              <Box as="h2" fontSize="40px" fontWeight="500" color="#252869">
                Are you interested in becoming a principal?
              </Box>
              <Box
                as="p"
                fontSize="18px"
                color="#252869"
                marginTop="15px"
                width="80%"
              >
                Let's connect and discuss how we can collaborate, get in touch
                with our corporate partnership team.
              </Box>
              <Button
                marginTop="25px"
                width="40%"
                variant="outline"
                colorScheme="#fff"
                background="#fff"
                color="#252869"
                borderRadius="25px"
                overflow="hidden"
                _hover={{ background: "#252869", color: "#fff" }}
              >
                <a href="mailto:partnership@kaminds.com">Contact</a>
              </Button>
            </Flex>
          </Flex>
          <Box
            top="-80px"
            right={["0px", "0px", "0px", "-20px", "-50px"]}
            position="absolute"
            borderRadius="30px"
            overflow="hidden"
            width={["100%", "80%", "80%", "45%", "45%"]}
          >
            <StaticImage
              style={{ borderRadius: "30px" }}
              width={570}
              height={300}
              alt="partnership"
              src="../images/partnership.jpg"
            />
          </Box>
        </Box>
      </Box>
      <Box marginTop="120px" padding="2em">
        <Box
          maxWidth="1300px"
          margin="auto"
          background="aliceblue"
          borderRadius="30px"
          padding="2em"
          position="relative"
        >
          <Box
            width="30%"
            right="-5%"
            top="-10%"
            position="absolute"
            borderRadius="30px"
            overflow="hidden"
            display={["none", "none", "none", "block", "block"]}
          >
            <StaticImage
              style={{
                borderRadius: "30px",
                overflow: "hidden",
              }}
              src="../images/office.jpg"
              width={700}
              height={540}
              alt="office"
            />
          </Box>
          <Flex direction="column">
            <Box
              as="h1"
              textAlign="center"
              color="#252869"
              fontSize="48px"
              fontFamily="Fjalla One !important"
            >
              Our
              <br /> Locations
            </Box>
            <Flex marginTop="30px" direction="column">
              <Box
                as="h2"
                fontSize="40px"
                color="#252869"
                fontFamily="Fjalla One !important"
                paddingLeft="20px"
              >
                India
              </Box>
              <Box
                as="h2"
                fontSize="30px"
                color="#252869"
                fontFamily="Fjalla One !important"
                paddingLeft="20px"
                marginTop="30px"
              >
                Kaminds Nutrichem Private Limited
              </Box>
              <Flex
                width={["100%", "100%", "80%", "55%", "55%"]}
                justifyContent="space-between"
                wrap="wrap"
              >
                <Box>
                  <Box
                    width={["100%", "100%", "50%", "50%", "50%"]}
                    as="h2"
                    fontSize="20px"
                    fontWeight="500"
                    color="#252869"
                    paddingLeft="25px"
                    marginTop={["35px", "35px", "30px", "25px", "25px"]}
                  >
                    Address
                  </Box>
                  <Box
                    as="address"
                    paddingLeft="25px"
                    marginTop="20px"
                    color="#252869"
                    fontStyle="normal"
                  >
                    S-52, First Floor, RIICO Commercial Complex,
                    <br />
                    Road No. 14, V.K.I. Area,
                    <br /> Jaipur - 302013,
                    <br /> Rajasthan, India
                  </Box>
                </Box>
                <Box>
                  <Box
                    width={["100%", "100%", "50%", "50%", "50%"]}
                    as="h2"
                    fontSize="20px"
                    fontWeight="500"
                    color="#252869"
                    paddingLeft="25px"
                    marginTop={["35px", "35px", "30px", "25px", "25px"]}
                  >
                    Contact
                  </Box>
                  <Box paddingLeft="25px" marginTop="20px" color="#252869">
                    Phone:{" "}
                    <a
                      href="tel:+917568015258"
                      style={{ textDecoration: "underline" }}
                    >
                      (+91) 75680 15258
                    </a>
                    <br />
                    Email:{" "}
                    <a
                      href="mailto:contact@kaminds.com"
                      style={{ textDecoration: "underline" }}
                    >
                      contact@kaminds.com
                    </a>
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Layout2>
  );
};

export default ContactPage;
