import React, { useState } from "react";
import {
  Link as Anchor,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputLeftAddon,
  InputGroup,
  Textarea,
  Select,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  COUNTRIES,
  getCountryDialCode,
  getCountryFull,
} from "../components/countries";
import { AsYouType } from "libphonenumber-js";

const ContactForm = () => {
  const [showThankYou, setShowThankYou] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    company: "",
    countryISO: "",
    country: "",
    dialcode: "",
    phone: "",
    market: "",
    message: "",
  });

  const onFormChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("name", formState.name);
    form.append("email", formState.email);
    form.append("company", formState.company);
    form.append("country", formState.country);
    form.append("phone", `(${formState.dialcode}) ${formState.phone}`);
    form.append("market", formState.market);
    form.append("message", formState.message);

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzyW__nZHI7yWNz-Ldi7Qfo67z3d4TZ4u_L6bjG7m9qLwevYKI2MnHvND45jWiF9Yth/exec";
    fetch(scriptURL, { method: "POST", body: form })
      .then((res) => res.json())
      .then((response) => {
        setShowThankYou(true);
        setFormState({
          name: "",
          email: "",
          company: "",
          countryISO: "",
          country: "",
          dialcode: "",
          phone: "",
          market: "",
          message: "",
        });
        setTimeout(() => {
          setShowThankYou(false);
        }, 10000);
      });
    //   .catch((error) => {
    //     return;
    //   });
    // const scriptURL =
    //   "https://www.zohoapis.in/crm/v2/functions/insertrecordtoleads/actions/execute?auth_type=apikey&zapikey=1003.455d1578b8a2a085773e90ec5788eaef.29160adb37e05852a797fe957bfb3aa1";
    // fetch(scriptURL, { method: "POST", body: form })
    //   .then((response) => console.log("Success!", response))
    //   .catch((error) => console.error("Error!", error.message));
  };

  const onCountryChange = (e) => {
    const value = e.target.value;
    const code = getCountryDialCode(value);
    const country = getCountryFull(value);
    console.log(country);
    const parsedNumber = new AsYouType().input(`${code}`);
    setFormState({
      ...formState,
      countryISO: value,
      country: getCountryFull(value),
      dialcode: parsedNumber,
    });
  };

  return showThankYou ? (
    <Box
      as="h3"
      fontSize="24px"
      width={["100%", "100%", "100%", "50%", "50%"]}
      alignSelf="center"
    >
      Thank you for contacting Kaminds. We will make sure the right person gets
      back to you as soon as possible.
    </Box>
  ) : (
    <Box
      width={["100%", "100%", "100%", "50%", "50%"]}
      marginTop={["50px", "50px", "50px", "0px", "0px"]}
      as="form"
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <FormControl as="text" width="calc(100% / 2 - 20px)">
        <FormLabel margin="0.5rem" color="#252869">
          Name*
        </FormLabel>
        <Input
          type="text"
          name="name"
          value={formState.name}
          onChange={onFormChange}
        />
      </FormControl>
      <FormControl as="email" width="calc(100% / 2 - 20px)">
        <FormLabel margin="0.5rem" color="#252869">
          Email address*
        </FormLabel>
        <Input
          type="email"
          name="email"
          value={formState.email}
          onChange={onFormChange}
        />
      </FormControl>
      <FormControl as="text" width="calc(100% / 2 - 20px)" marginTop="15px">
        <FormLabel margin="0.5rem" color="#252869">
          Company*
        </FormLabel>
        <Input
          type="text"
          name="company"
          value={formState.company}
          onChange={onFormChange}
        />
      </FormControl>
      <FormControl as="text" width="calc(100% / 2 - 20px)" marginTop="15px">
        <FormLabel margin="0.5rem" color="#252869">
          Country*
        </FormLabel>
        <Select
          rootProps={{
            fontFamily: "Roboto !important",
          }}
          fontFamily="Roboto !important"
          placeholder="---"
          name="country"
          value={formState.countryISO}
          onChange={onCountryChange}
        >
          {COUNTRIES.map((country) => (
            <option value={country.iso}>{country.name}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl as="text" width="calc(100% / 2 - 20px)" marginTop="15px">
        <FormLabel margin="0.5rem" color="#252869">
          Phone
        </FormLabel>
        <InputGroup>
          <InputLeftAddon children={formState.dialcode} />
          <Input
            type="number"
            name="phone"
            value={formState.phone}
            onChange={onFormChange}
          />
        </InputGroup>
      </FormControl>
      <FormControl width="calc(100% / 2 - 20px)" marginTop="15px">
        <FormLabel margin="0.5rem" color="#252869">
          Choose Your Market*
        </FormLabel>
        <Select
          placeholder="---"
          name="market"
          value={formState.market}
          onChange={onFormChange}
        >
          <option value="human-nutrition">Human Nutrition</option>
          <option value="animal-nutrition">Animal Nutrition</option>
          <option value="food">Food</option>
          <option value="personal-care">Personal Care</option>
          <option value="industrial-uses">Industrial Uses</option>
        </Select>
      </FormControl>
      <FormControl as="text" width="100%" marginTop="15px">
        <FormLabel margin="0.5rem" color="#252869">
          Enquiry/Feedback*
        </FormLabel>
        <Textarea
          size="sm"
          name="message"
          value={formState.message}
          onChange={onFormChange}
        />
      </FormControl>
      <Button
        onClick={submitForm}
        colorScheme="#fff"
        variant="outline"
        marginTop="20px"
        background="#fff"
        color="#252869"
        _hover={{
          background: "#252869",
          color: "#fff",
        }}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default ContactForm;
